@import '../../styles/customMediaQueries.css';

.imagePreviewRoot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
}

.imagePreviewWrapper {
  position: relative;
  display: flex;
  max-width: 100vw;
  max-height: 80vh;

  background-color: rgba(0, 0, 0, 0.9);

  @media (--viewportMedium) {
    max-width: 80vw;
    border-radius: 8px;
  }
}

.imagePreviewImage {
  display: none;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;

  @media (--viewportMedium) {
    border-radius: 8px;
  }
}

.selectedImage {
  display: unset;
}

.navLeft,
.navRight {
  position: absolute;
  width: 46px;
  height: 46px;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  top: 50%;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}

.closeButton {
  position: absolute;
  width: 46px;
  height: 46px;
  border: 0;
  padding: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  right: 0;
  top: 0;
}

.closeButton:hover {
  cursor: pointer;
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper,
.closeButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  border-radius: 50%;

  background-color: rgba(237, 241, 240, 0.8);
}

.closeButtonIcon {
  width: 14px;
  height: 14px;
  opacity: 0.8;
}

.prevArrowIcon {
  width: 24px;
  height: 24px;

  transform: rotate(-180deg);
}

.nextArrowIcon {
  width: 24px;
  height: 24px;
}
