.labelWrapper {
  display: flex;
  justify-content: space-between;
  height: 55px;
  align-items: center;
}

.labelWrapper:hover {
  cursor: pointer;
}

.label {
  margin: 0;
  padding: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212529;
}

.label:hover {
  cursor: pointer;
}

.arrowIcon {
  height: 24px;
  width: 24px;
}
