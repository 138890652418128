@import '../../../styles/customMediaQueries.css';

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.navLeft {
  left: 0;
  top: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;
  top: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.2);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.root {
  padding: 16px 0;

  @media (--viewportLarge) {
    padding: 0;
    position: sticky;
    top: 136px;
    /* z-index: 10; */
  }
}

.rootWithPreview {
  z-index: 10;
}

.imagePreviewRoot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1001;
}

.imagePreviewWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 370px;

  background: #d9d9d9;
  border-radius: 4px;

  @media (--viewportLarge) {
    height: 520px;
  }
}

.imagePreviewImage {
  display: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.selectedImage {
  display: unset;
}

.selectedImage:hover {
  cursor: pointer;
}

.imageCarouselPreviewRoot {
  display: flex;
  flex-direction: row;
  gap: 16px;

  height: 80px;
  overflow: auto;

  @media (--viewportLarge) {
    height: 132px;
  }
}

.imageCarouselImageWrapper {
  display: flex;
  height: 100%;
  min-width: 132px;
  max-width: 132px;
  border-radius: 4px;
}

.imageCarouselImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.imageCarouselImage:hover {
  cursor: pointer;
}
