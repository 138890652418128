@import '../../styles/customMediaQueries.css';

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 16px;
}

.errorText,
.errorText > span {
  color: var(--failColor);
  margin: 16px;
}

.icon {
  height: 24px;
  width: 24px;
}

.authorWrapper {
  height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: 424px;
    flex-basis: calc(100% - 348px);
    flex-shrink: 0;
    flex-grow: 1;
    padding-left: 16px;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.contentWrapperForProductLayout {
  margin: 0;
  padding: 0 16px;

  @media (--viewportMedium) {
    width: 100%;
    max-width: 1056px;
    padding: 0 16px 0 16px;
    margin: 0 auto 0 auto;
  }

  @media (--viewportLarge) {
    display: flex;
    gap: 24px;
    flex-direction: row;
    justify-content: center;
    max-width: 1320px;
    padding: 40px 16px;
  }

  @media (min-width: 1320px) {
    padding: 40px 0px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 16px;
    position: sticky;
    top: 108px;
  }
}

.sectionHeading {
  margin-top: 0;
  margin-bottom: 16px;
}

.mobileTopbar {
  @media (--viewportLarge) {
    display: none;
  }
}

.desktopTopbar {
  display: none;

  @media (--viewportLarge) {
    display: unset;
  }
}

.desktopPriceContainer {
  /* display: none; */

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.desktopHeadingActions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
}

.likeIcon {
  height: 24px;
  width: 24px;
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;

    margin-bottom: 0;
    padding: 0;
    min-height: 54px;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.richTitle {
  text-overflow: ellipsis;
  overflow: auto;

  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0em;
  color: var(--matterColorDark);

  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
  }

  @media (--viewportLarge) {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }
}

.authorNameLink {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #343a40;

  text-decoration: underline !important;
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.sectionDescription {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionShoppingDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shoppingDetailsInfoWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.shoppingDetailsInfoImage {
  margin-top: 2px;
  height: 24px;
  width: 24px;
}

.shoppingDetailsInfoSellImage {
  margin-top: 2px;
  height: 20px;
  width: 20px;
}

.shoppingDetailsTitle {
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.shoppingDetailsSellTitle {
  margin: 0 0 0 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.shoppingDetailsSellDescription {
  margin: 0 0 0 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.shoppingDetailsDescription {
  margin: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
}

.sectionDetails {
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.detailLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #6c757d;
}

.detailValue {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: var(--matterColorDark);
}

.sectionAuthor {
  position: relative;
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100dvh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  @media (--viewportMedium) {
    margin: 16px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
    padding-bottom: 4px;
    width: 872px;
    height: 100%;
  }
}
/*
.productMobileHeading {
  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
} */

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 16px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.divider {
  border-top: 1px solid #e9ecef;
}

.sectionDivider {
  border-top: 1px solid #e9ecef;
}

.sectionOfferedBy {
  margin: 24px 0 16px 0;
}

.listingPageGroupLabelWrapper {
  display: flex;
  align-items: center;
  height: 40px;
}

.listingPageGroupLabel {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212529;
}

.sectionDescriptionPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
}

.basicInformationLabel {
  margin: 0;
  padding: 0;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0em;
  color: #6c757d;
}

.sectionDescriptionPanelValue {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  color: #343a40;
}

.description {
  word-wrap: break-word;
  white-space: pre-line;

  margin: 8px 0 16px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #343a40;
}

.locationSpan {
  color: #6c757d;
}

.authorSpan {
  color: #6c757d;
}

.orderButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  width: 269px;
  height: 56px;
}

.buyNowButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  flex: 1;
  height: 44px;
  min-height: 44px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.contactSellerButton {
  composes: button buttonFont buttonText buttonBordersSecondary buttonSecondaryColors from global;

  flex: 1;
  height: 44px;
  min-height: 44px;

  box-sizing: border-box;
  border: 1px solid #235641;
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #235641;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.likeListingButton {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid #495057;
  height: 44px;
  min-height: 44px;
  width: 100%;

  color: #495057;
  background-color: white;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.likeListingButton:hover {
  cursor: pointer;
}

.priceValue {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  text-align: left;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
}

.priceContainer {
  display: flex;
  align-items: center;
  height: 44px;
}

.itemBadges {
  display: flex;
  flex-direction: row;
}

.badgeRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  background: #edf1f0;
  border-radius: 15px;
}

.badgeText {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--matterColorDark);
}

.phoneButton {
  composes: button buttonFont buttonText buttonSecondaryAccentBorder buttonSecondaryAccentColors from global;
  min-height: 44px;

  @media (--viewportMedium) {
    width: 350px;
  }
}

.loaderWrapper {
  height: 80dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onlyMobile {
  display: block;
  width: 100%;
  max-width: 100%;

  @media (--viewportMedium) {
    display: none;
  }
}

.paymentMethodWrapper {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.paymentMethodImage {
  width: 40px;
  height: 28px;
}

.infoBoxWrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  background: #fafafa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
}

.infoBoxLabel {
  color: #212529;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 0;
  margin: 8px 0;
}

.infoBoxDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
  margin: 8px 0;
}

.infoBoxPropositionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 40px;
}

.infoBoxPropositionLogo {
  width: 24px;
  height: 24px;
}

.infoBoxPropositionText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
}

.similarItemsContainer {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.similarItemsHeader {
  margin: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (--viewportMedium) {
    padding: 0 24px;
    margin: 16px 0;
  }

  @media (min-width: 1440px) {
    max-width: 1320px;
    margin: 16px auto;
    padding: 0;
  }

  @media (max-width: 340px) {
    flex-wrap: wrap;
  }
}

.similarItemsHeader > button {
  height: 34px;
  width: fit-content;
  padding: 0 16px;
  white-space: nowrap;
}

.similarItemsContainerTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 0;
  margin-top: 0;
}

.descriptionLabel {
  margin: 16px 0;
  padding: 0;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #212529;
}

.payAtGearroCardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #edf1f0;
  border-radius: 8px;
}

.payAtGearroCardText {
  margin: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #495057;
}
